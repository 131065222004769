<template>
  <v-app>
  <!--<div id="app">
    <div id="nav">
      <router-link to="/">Shop</router-link> |
      <router-link to="/Profile">Perfil</router-link>|
      <router-link to="/Calendar">Calendario</router-link> |
      <router-link to="/TimeControl">Control horario</router-link>|
      <router-link to="/Login">Salir</router-link>
    </div>
  </div>-->
    <v-main> 
      <AlertaFichaje v-if="mostrarAlertaFichaje" :key="componentKey"/>
      <router-view/>
    </v-main>
    <alerta :error='error' :errors='errors' :color='colorAlerta' @closeError="error=false" />
    <confirm ref="confirm"></confirm>
  </v-app>
</template>

<script lang="ts">
import Vue from 'vue';
import AlertaFichaje from './components/AlertaFichaje.vue'
import Alerta from './components/Alerta.vue';
import Confirm from './components/Confirm.vue';
import axios from "axios";

const moment = require("moment")

export default Vue.extend({
  name: 'App',
   
  data: () => ({
      sheet: true,
      componentKey: 0,
      mostrarAlertaFichaje: false,
      error: false,
      errors: [],
      colorAlerta: '',
      sesion: null  
  }), 
  computed: {
    isSignedUp () {
      return this.$store.state.userInfo.isSignedUp
    },
    extenderIlimitado () {
      return this.$store.state.userInfo.extenderIlimitado
    },
    extender10Min () {
      return this.$store.state.userInfo.extender10Min
    },
    minutosTrabajados () {
      return this.$store.state.userInfo.minutosTrabajados
    },
    trabajando () {
      return this.$store.state.userInfo.trabajando 
    },
    minutosATrabajar () {
      return this.$store.state.userInfo.minutosATrabajar
    },
  },
  watch: {
    isSignedUp() {
      this.componentKey += 1
    },
    minutosTrabajados(value) {
      // console.log(value)
      if (moment().diff(this.$store.state.userInfo.diaTrabajo, 'days') != '0') {
        this.$store.state.userInfo.extender10Min =  false
        this.$store.state.userInfo.extenderIlimitado = false
        this.$store.state.userInfo.diaTrabajo = moment().startOf('day')
        this.mostrarAlertaFichaje = false
        this.$store.state.userInfo.minutosTrabajados = 0
        clearInterval(this.intervalMinutosTrabajados)
      }
      if (this.isSignedUp && !this.extenderIlimitado && this.trabajando && this.$store.state.userInfo.minutosTrabajados !== 0) {
        if ((value > this.minutosATrabajar + 10) && !this.extender10Min) {
          this.mostrarAlertaFichaje = true
          this.componentKey += 1
        } else if (value > this.minutosATrabajar + 30) {
          this.mostrarAlertaFichaje = true
          this.componentKey += 1
        } else {
          this.mostrarAlertaFichaje = false
        }
      } else {
        this.mostrarAlertaFichaje = false
      }
    },
  },
  components: {
      AlertaFichaje,
      Confirm,
      Alerta
    },
  sockets: {
      connect: function () {
        console.log('socket connected')
      },
      conexion_duplicada: function (data) {
        if (data.sesion == this.$store.getters.getSesion) {
          var url = "/MobileLogin"
          if (Vue.prototype.isSys4Conjunto()) {
            url += '/' + localStorage.getItem('instalacion')
          }
          this.$store.reset()
          this.$store.commit('logout')
          this.$router.push(url)
        }
      },
      conexion_inactiva: function (data) {
        if (data.sesion == this.$store.getters.getSesion) {
          var url = "/MobileLogin"
          if (Vue.prototype.isSys4Conjunto()) {
            url += '/' + localStorage.getItem('instalacion')
          }
          this.$store.reset()
          this.$store.commit('logout')
          this.$router.push(url)
        }
      }
  },
  created() {
    // this.sesion = localStorage.getItem('idSesion');
    if (this.$store.getters.getSesion == null) {
        // this.sesion = this.uniqid();
        // localStorage.setItem('idSesion', this.sesion);
        this.$store.commit('setSesion', this.uniqid())
    } else {
        console.log(this.$store.getters.getSesion);
    }
    this.emitirPing()
    this.$vuetify.lang.current = 'es'
  },
  mounted() {
    this.$root.$on('mostrarAlerta', (mensajes, color) => {
      this.mostrarAlerta(mensajes, color)
    });
    this.$root.$on('verificarCertificado', (url) => {
      this.verificarCertificado(url)
    });
    this.$root.$confirm = this.$refs.confirm
  },
  methods: {
    emitirPing() {
      var vue = this
      setInterval( function() {
        if (vue.$store.getters.getSesion === null) {
          vue.$store.commit('setSesion', vue.uniqid())
        }
        console.log(vue.$store.getters.getSesion)
        vue.$socket.emit("ping", vue.$store.getters.getSesion);
      }, 5000);
    },
    uniqid(prefix = "", random = false) {
      const sec = Date.now() * 1000 + Math.random() * 1000;
      const id = sec.toString(16).replace(/\./g, "").padEnd(14, "0");
      return `${prefix}${id}${random ? `.${Math.trunc(Math.random() * 100000000)}`:""}`;
    },
    mostrarAlerta (mensajes, color) {
      this.error = true
      this.errors.length = 0
      this.errors.push(mensajes)
      this.colorAlerta = color
    },
    verificarCertificado(url) {
      var vueAux = this
      axios({
        method: 'get',
        url: url,
      })
        .then(function (response) {
          if (response.status === 200) {
            console.log(url)
          }
        })
        .catch(function (error) {
          if (error.response !== undefined && error.response.status !== 404) {
            // error raro
            console.log(error)
            vueAux.mostrarAlerta('Error comprobando certificado', 'error')
            vueAux.$router.push({ name: 'Inicio' });
          } else if (error.response === undefined) {
            if (error.message === 'Network Error') {
              // error de certificado sacamos mensaje y lo mandamos a la pagina de inicio
              vueAux.mostrarAlerta('Certificado no instalado', 'error')
              vueAux.$router.push({ name: 'Inicio' });
            } else {
              vueAux.mostrarAlerta('Error comprobando certificado', 'error')
              vueAux.$router.push({ name: 'Inicio' });
              console.log(error)
            }
          } else {
            console.log(error)
          }
        })
    }
  }
});
</script>
