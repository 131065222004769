<template>
<div>

 <v-container fluid class="ranking">
    <v-row>
        <h2 class="primary--text px-2 py-6 text-center col-12">Ranking</h2>
    </v-row>
    <v-layout class="milayout">
      <v-row >
        <v-col  cols="10" sm="10">
          <v-select
            v-model="vcomboTiempo"
            :items="comboTiempo"
            item-value="id"
            item-text="name"
            class="pl-8"
            @change="actualizarRanking()"
          ></v-select>
          <v-select
              v-show="true"
              :items="comboSeccion"
              item-value="id"
              item-text="name"
              v-model="vcomboSeccion"
              @change="actualizarRanking()"
              class="combo-margin pl-8 pt-0 mt-0"
            >
          </v-select>
        </v-col>
         <v-col  cols="2" sm="2">
            <transition name="fade" mode="out-in">
              <v-btn  key=1 class="btn-ranking elevation-0 secondary" fab dark small color="indigo" @click="cambiarVista()" v-if="vistaGrid==='galeria'">
                <v-icon class="icon list"></v-icon>
              </v-btn>
              <v-btn  key=2 class="btn-ranking elevation-0 secondary" fab dark small color="indigo" @click="cambiarVista()" v-else>
                <v-icon class="icon modulo"></v-icon>
              </v-btn>
            </transition>
        </v-col>
        <v-col  cols="12" sm="12" class="columna-content">
          <transition name="fade" mode="out-in">
          <!-- Modo galeria -->
          <v-row  key=3 v-if="vistaGrid==='galeria'">
            <v-col
              v-for="n in arrayRanking"
              :key="n.ps0001_id"
              class="d-flex child-flex"
              cols="6" sm="3" md="2"
            >
              <v-card flat tile class="d-flex elevation-0" outlined shaped >
                <v-img
                  :src="`data:image/png;base64, ${n.psart_imagen}`"
                  :lazy-src="require('@/assets/images/noimg.jpg')"
                  aspect-ratio="1"
                  contain
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
                 <v-avatar
                    color="primary"
                    size="36"
                  >
                       <span class="white--text">{{n.ps0001_unidades_vendidas}}</span>
                  </v-avatar>
              </v-card>
            </v-col>
          </v-row>
          <!--  -->
          <!-- Modo lista -->
          <v-row key=4 v-else>
            <v-col
              v-for="n in arrayRanking"
              :key="n.id.ps0001_art_identity"
              class="d-flex child-flex"
              cols="12" sm="12" md="12"
            >
              <v-card flat tile class="d-flex elevation-0 pa-0" outlined shaped elevation="5">
                <div class="d-flex pa-0 flex-no-wrap justify-space-between">
                   <v-avatar
                      class="ma-3"
                      size="125"
                      tile
                    >
                      <v-img :src="`data:image/png;base64, ${n.psart_imagen}`"
                            :lazy-src="require('@/assets/images/noimg.jpg')"
                            aspect-ratio="1"
                           contain
                           >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-avatar>
                  <div>
                    <v-card-title
                      class="headline"
                      v-text="`Id: ${n.ps0001_art_id}`"
                    ></v-card-title>
                    <v-card-text>
                               <v-list-item-subtitle>Ref {{n.psart_refer}}</v-list-item-subtitle>
                               <v-list-item-subtitle>Color {{n.color}}</v-list-item-subtitle>
                               <v-list-item-subtitle>Vendidas {{n.ps0001_unidades_vendidas}}</v-list-item-subtitle>
                               <v-list-item-subtitle>Stock {{n.ps0001_stock}}</v-list-item-subtitle>
                               <v-list-item-subtitle>ROI {{n.roi}}</v-list-item-subtitle>
                    </v-card-text>
                  </div>
                </div>
              </v-card>
            </v-col>
          </v-row>
          <!--  -->
          </transition>
        </v-col>
      </v-row>

    </v-layout>
  </v-container>
</div>
</template>
<script>

import { PsApiService } from '@/api/PsApiService'
import { Util } from '@/api/Util'

const psApiService = new PsApiService()
const util = new Util()

export default {
     components: {

    
  },
  data () {
    return {
      vcomboTiempo: 1,
      comboTiempo: [
        {
          id: 1,
          name: 'Ayer'
        },
        {
          id: 2,
          name: 'Hoy'
        },
        {
          id: 3,
          name: 'Semana'
        },
        {
          id: 4,
          name: 'Mes'
        }
      ],
      vistaGrid: 'galeria', // por defecto vista galería
      arrayRanking: [],
      vcomboSeccion: '----',
      comboSeccion: [],
      opUid: this.$store.getters.getOpuid,
    }
  },
  computed: {
    regenerar () {
      return this.$store.getters.isRegenerarTablas
    },
    cambiotienda() {
      return this.$store.getters.getOpuid;
    }
  },
  watch: {
    regenerar (value) {
      console.log(`My store value for 'regenerar' changed to ${value}`)
      this.actualizarRanking()
    },
    cambiotienda(value) {
      this.opUid = value;
      this.actualizarRanking();
    }
  },
  methods: {
    getAllSeccion () {
      psApiService
        .getAllSeccion()
        .then(data => {
          this.comboSeccion = data
          
          this.actualizarRanking()
        })
        .catch(error => {
          util.mostrarEnConsola('getAllSeccion', error, 'ERROR')
        })
    },
    actualizarRanking () {
      psApiService
        .getRankingTop20(this.vcomboTiempo, this.vcomboSeccion, this.opUid)
        .then(data => {
          this.arrayRanking = data
        })
        .catch(error => {
          util.mostrarEnConsola(
            'getRankingTop20',
            error,
            'ERROR'
          )
          this.arrayRanking = []
        })
    },
    rutaImagen (idImg) {
      console.log(idImg)
      if (
        idImg !== null &&
        idImg !== undefined &&
        idImg !== 0 &&
        !isNaN(idImg)
      ) {
        try {
          return require(`@/assets/image/${idImg}.jpg`)
        } catch {
          console.log('error imagen ' + idImg)
        }
      }
    },
    cambiarVista () {
      if (this.vistaGrid === 'lista') {
        this.vistaGrid = 'galeria'
      } else {
        this.vistaGrid = 'lista'
      }
    }
  },
  created () {
    this.getAllSeccion()
    this.actualizarRanking()
  }
}
</script>
