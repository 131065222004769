<template>
  <v-app class="shop">
    <header class="pl-13 v-app-bar" style="background: #fff;">
      <div class="v-toolbar__content" style="height: 56px;">
        <div elevation="0" class="pl-0 header-icon">
          <v-icon class="icon carro"></v-icon>
        </div>
        <Header />
      </div>
    </header>
    <Menu />
    <v-main>
      <v-card class="elevation-0">
        <v-card-title v-if="productos.length === 0">No hay articulos</v-card-title> 
        <!-- <v-tabs
                v-model="tab"
                show-arrows 
                class="elevation-1"
                @change="cambiarTab()"
              >
                <v-tab
                  v-for="item in items"
                  :key="item.tab"
                >
                  {{ item.tab }}
                </v-tab>
              </v-tabs>
              <v-tabs-items v-model="tab"  @change="cambiarTab()">
                <v-tab-item
                  v-for="item in items"
                  :key="item.tab"
                  class="tranparent"
                >
                    <v-card-text class="margin-top pa-0 elevation-0">
                      <v-parallax
                        height="190"
                        :src="item.src"
                        contain
                      > 
                        <v-col class="col-8">
                          <h1 class="text-uppercase d-flex">{{ item.title }}</h1>
                          <h2 class="font-weight-regular subtitle-1 ">{{ item.content}}</h2>
                       </v-col>
                      </v-parallax>
                      <v-btn
                            icon
                            large
                            class="icon filtro"
                            absolute 
                            @click.stop="mini = !mini"
                          ></v-btn>
                    </v-card-text> -->
        <v-container fluid>
          <div v-if="cargando" class="mt-15 text-center">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
          </div>
          <v-row class="mt-15" dense v-else>
            <v-card-title class="text-center" v-if="productos.length === 0">No hay articulos</v-card-title> 
            <v-col v-for="product in productos" :key="product.id" :cols="6">
              <router-link class="flex-grow-0 text-decoration-none" :to="{ name: 'ProductPage', params: { productId: product.id } }">
                <producto-lista :product="product" :opId="opId" />
              </router-link>
            </v-col>
          </v-row>
        </v-container>
        <!-- </v-tab-item>
              </v-tabs-items> -->
      </v-card>
    </v-main>
    <Pagination v-show="!cargando && productos.length > 0"></Pagination>
  </v-app>
</template>

<script lang="ts">
import Vue from 'vue';
import Menu from '../components/Menu.vue';
import Header from '../components/Shop/SHeader.vue';
import Pagination from './Pagination.vue';
import ProductoLista from './ProductoLista.vue';
import { TiendaApiService } from '../api/TiendaApiService';
const tiendaApiService = new TiendaApiService();

export default Vue.extend({
  name: 'Shop',
  components: {
    Menu,
    Header,
    ProductoLista,
    Pagination,
  },
  data() {
    return {
      tab: { tab: 'Todos', title: 'Todos', content: 'Descripción', src: require('@/assets/images/todos.jpg'), seccion: { id: 0, codif: '03//1' } },
      overlay: false,
      opId: '',
      npag: 1,
      items: [
        { id: 0, tab: 'Todos', title: 'Todos', content: 'Descripción', src: require('@/assets/images/todos.jpg') },
        { id: 1, tab: 'Deportivas', title: 'deportivas', content: 'Descripción', src: require('@/assets/images/deportivas.jpg'), seccion: { codif: 1, descripcion: 'CASUAL & SPORT', fechaModificacion: '2011-11-23T18:57:17.790+0000', id: '03//1', idioma: 17, rgb: 'FFFFFF', tipo: 0, uid: 'B4C8EB0A-608F-4FA1-A8E7-C0841A1E3F6D' } },
        { id: 2, tab: 'Sandalias', title: 'sandalias', content: 'Descripción', src: require('@/assets/images/sandalias.jpg'), seccion: { codif: 1, descripcion: 'SANDALS', fechaModificacion: '2011-11-23T18:57:17.790+0000', id: '05//1', idioma: 17, rgb: 'FFFFFF', tipo: 0, uid: 'EE8E9335-3135-4FAA-9027-BDAE7A0E6C7C' } },
        { id: 3, tab: 'Zapatos', title: 'Zapatos', content: 'Descripción', src: require('@/assets/images/zapatos.jpg'), seccion: { codif: 1, descripcion: 'DRESSING', fechaModificacion: '2011-11-23T18:57:17.790+0000', id: '04//1', idioma: 17, rgb: 'FFFFFF', tipo: 0, uid: 'BDC63F16-0956-4203-A62D-05D9D7518B08' } },
        { id: 4, tab: 'Botas', title: 'Botas', content: 'Descripción', src: require('@/assets/images/botas.jpg'), seccion: { codif: 1, descripcion: 'Bota', fechaModificacion: '2016-12-12T11:02:16.447+0000', id: '13//1', idioma: 17, rgb: 'FFFFFF', tipo: 0, uid: '9D0BF4C1-307D-45B8-A522-5DFB28A01789' } },
      ],
      productos: [],
      drawer: true,
      cargando: false,
      seccion: [],
    };
  },
  computed: {
    hasSearched() {
      return this.$store.state.tienda.hasSearched;
    },
    cambiotienda() {
      return this.$store.getters.getIdTienda;
    },
    cambioPag() {
      return this.$store.getters.getNpag;
    },
  },
  watch: {
    cambiotienda(value) {
      this.opId = value;
      this.$store.commit('cambioPag', 1);
      this.init();
    },
    cambioPag(value) {
      this.npag = value;
      this.init();
    },
    hasSearched() {
      this.init();
    },
  },
  created() {
    this.$root.$emit('verificarCertificado', location.href)
  },
  mounted() {
    // this.$store.commit('cambioPag', 1);
    (this.opId = this.$store.getters.getIdTienda), (this.npag = this.$store.getters.getNpag), this.init();
  },
  methods: {
    init() {
      this.getProductBy();
    },
    getAllArticulos() {
      this.cargando = true;
      tiendaApiService
        .getAllArticulos(this.npag - 1)
        .then((data) => {
          this.cargando = false;
          if (data) {
            this.productos = data.content;
            this.$store.state.tienda.totalPag = data.totalPages;
          } else {
            this.cards = [];
          }
        })
        .catch((error) => {
          this.cargando = false;
          // alert('Ha ocurrido un error')
          this.cards = [];
          console.log('tiendaApiService - getAllArticulos ' + error);
        });
    },
    getProductBy() {
      var filtro = {
        opid: this.opId,
        npag: this.npag,
        stock: false,
        stockOpId: '',
        modelo:'',
        seccion: [],
        temporada: [],
        color: [],
        talla: [],
        precio: [0, 400000]
      };
      if (this.$store.state.tienda.productEanSearched !== '') {
        /* miramos si hay busqueda por ean */
        let ean = this.$store.state.tienda.productEanSearched;
        Object.assign(filtro, { ean: ean });
      } else if (this.$store.state.tienda.productLocalizadorSearched !== '') {
        /* miramos si la busqueda es por localizador */
        let localizador = this.$store.state.tienda.productLocalizadorSearched;
        if (isNaN(localizador)) {
          //si el localizador tiene letras buscamos por modelo
          Object.assign(filtro, { modelo: localizador });
        } else {
          Object.assign(filtro, { localizador: localizador });
        }
      }
      if (this.seccion.length > 0) {
        Object.assign(filtro, { seccion: this.seccion });
      }
      this.cargando = true;
      //console.log(filtro)
      tiendaApiService
        .findArticulos(filtro)
        .then((data) => {
          this.cargando = false;
          if (data) {
            this.productos = data.data.content;
            this.$store.state.tienda.totalPag = data.data.totalPages;
            if (data.data.content.length === 1) {
              this.$router.push({ name: 'ProductPage', params: { productId: data.data.content[0].id } });
              this.$store.commit('addToHistorialBusqueda', {
                src: this.getFoto(data.data.content[0]),
                title: data.data.content[0].modelo,
                subtitle: 'loc.' + data.data.content[0].localizador,
              });
            }
          } else {
            this.productos = [];
          }
        })
        .catch((error) => {
          this.cargando = false;
          // alert('Ha ocurrido un error')
          this.productos = [];
          console.log('tiendaApiService - findArticulos ' + error);
        });
      //return this.$store.state.products;
      this.$store.commit('setProductEanSearched', '');
    },
    getFoto(articulo) {
      let imagen = '';
      if (articulo.fotos) {
        articulo.fotos.forEach((element) => {
          if (element.porDefecto === 'S') {
            if (element.url) {
              imagen = element.url;
            } else {
              imagen = 'data:image/png;base64,' + element.imagenBytes;
            }
          } else {
            if (imagen === '') {
              if (element.url) {
                imagen = element.url;
              } else {
                imagen = 'data:image/png;base64,' + element.imagenBytes;
              }
            }
          }
        });
      }
      return imagen;
    },
    cambiarTab() {
      this.seccion = [];
      //console.log(this.tab);
      if (this.tab === 0) {
        this.seccion = [];
        this.getProductBy();
      } else {
        this.seccion.push(this.items.filter((item) => item.id === this.tab)[0].seccion);
        this.getProductBy();
      }
    },
  },
});
</script>
