<template>
<div>
 
 <v-container fluid>
  <v-row>
      
        <h2 class="primary--text px-2 py-6 text-center col-12">Venta neta</h2>
     
  </v-row>
 
      <v-row>
        <!-- Si la instalacion es de twister no mostramos el combo tipo de tienda  -->
        <v-col  class="py-0 px-8" cols="12" sm="12" v-if="false">
          <v-select
            v-model="vcomboTipoTienda"
            :items="comboTipoTienda"
            item-value="id"
            item-text="name"
            Label="Tienda"
            @change="actualizarTabla()"
            class="pt-0 mt-0"
          ></v-select>
        </v-col>
        <!--  -->
        <v-col  cols="6" sm="6" class="pl-8">
          <v-select
             v-model="vcomboValor"
            :items="comboValor"
            item-value="id"
            item-text="name"
            label="Valor"
            class="pt-0 mt-0"
            @change="actualizarTabla()"
          ></v-select>
        </v-col>
        <v-col  cols="6" sm="6" class="pr-8">
          <v-select
            v-model="vcomboTiempo"
            :items="comboTiempo"
            item-value="id"
            item-text="name"
            value="Hoy"
            label="Fecha"
             class="pt-0 mt-0"
            @change="actualizarTabla()"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="12" class="pa-0">
          <v-data-table
            :headers="headers"
            :items="datosTabla"
            :sort-by="['tienda']"
            hide-default-footer
            :items-per-page= "100000"
            :mobile-breakpoint = "365"
            :loading = cargando
            class="pa-0 rounded-0"
            
          >
            <template v-slot:item.unidades="{ item }" v-if="vcomboValor === 1">
              <span>{{ item.unidades }}</span>
            </template>
            <template v-slot:item.valor="{ item }" v-else>
              <span>{{ formatNum(item.valor) }}€</span>
            </template>
            <template v-slot:item.unicomp="{ item }" v-if="vcomboValor === 1">
              <span :class="getColor(item)">{{ item.unicomp }}</span>
            </template>
            <template v-slot:item.valorcomp="{ item }" v-else>
              <span :class="getColor(item)">{{ formatNum(item.valorcomp) }}€</span>
            </template>
            <template v-slot:item.porcentajeUni="{ item }" v-if="vcomboValor === 1">
              <span :class="getColor(item)">{{ item.porcentajeUni }}%</span>
            </template>
            <template v-slot:item.porcentajeValor="{ item }" v-else>
              <span :class="getColor(item)">{{ item.porcentajeValor }}%</span>
            </template>
            <template v-slot:item.semaforo="{ item }">
              <v-chip :color="getColor(item)" dark>{{ item.semaforo }}</v-chip>
            </template>
            <template v-slot:body.append="{}" v-if="datosTabla.length">
                <tr class="totales">
                    <td class="text-center">TOTAL</td>
                    <td class="text-right">{{totalValor}}</td>
                    <td class="text-right">{{totalComp}}</td>
                    <td class="text-right">{{totalPorc}}%</td>
                    <td class="text-center"><v-chip :color="totalSem" dark></v-chip></td>
                </tr>
            </template>
            <template v-slot:no-data>
              <v-alert :value="true">
                No hay datos
              </v-alert>
            </template>
          </v-data-table>
        </v-col>
      </v-row>


      
 
  </v-container>
  </div>
</template>
<script>
import { PsApiService } from '@/api/PsApiService'
import { Util } from '@/api/Util'

const psApiService = new PsApiService()
const util = new Util()

export default {
    components: {
    
  },
  data () {
    return {
      cargando: false,
      vcomboValor: 2,
      comboValor: [
        {
          id: 1,
          name: 'Unidades'
        },
        {
          id: 2,
          name: 'Valor'
        }
      ],
      vcomboTiempo: 1,
      comboTiempo: [
        {
          id: 1,
          name: 'Ayer'
        },
        {
          id: 2,
          name: 'Hoy'
        },
        {
          id: 3,
          name: 'Semana'
        },
        {
          id: 4,
          name: 'Mes'
        },
        {
          id: 5,
          name: 'Año'
        }
      ],
      datosTabla: [],
      vcomboTipoTienda: '--',
      comboTipoTienda: []

    }
  },
  computed: {
    // a computed getter
    headers: function () {
      if (this.vcomboValor === 1) {
        return [
          {
            text: 'Tienda',
            align: 'center',
            sortable: true,
            value: 'tienda'
          },
          { text: `${this.comboTiempo[this.vcomboTiempo - 1].name}`, sortable: true, value: 'unidades', align: 'right' },
          { text: 'Comparativa', sortable: true, value: 'unicomp', align: 'right' },
          { text: '%', value: 'porcentajeUni', align: 'right' },
          { text: 'Semaforo', sortable: false, value: 'semaforo', align: 'center' }
        ]
      } else {
        return [
          {
            text: 'Tienda',
            align: 'center',
            sortable: true,
            value: 'tienda',
            class:'white--text'
          },
          { text: `${this.comboTiempo[this.vcomboTiempo - 1].name}`, sortable: true, value: 'valor', align: 'right', class:'white--text' },
          { text: 'Comparativa', sortable: true, value: 'valorcomp', align: 'right', class:'white--text' },
          { text: '%', value: 'porcentajeValor', align: 'right', class:'white--text' },
          { text: 'Semaforo', sortable: false, value: 'semaforo', align: 'center', class:'white--text' }
        ]
      }
    },
    regenerar () {
      return this.$store.getters.isRegenerarTablas
    },
    totalValor () {
      if (this.datosTabla.length) {
        let sum
        if (this.vcomboValor === 1) {
          sum = this.datosTabla.map(item => item.unidades).reduce((prev, next) => prev + next)
        } else {
          sum = this.datosTabla.map(item => item.valor).reduce((prev, next) => prev + next)
          sum = sum.toLocaleString(undefined, { minimumFractionDigits: 0 }) + '€'
        }
        return sum
      } else {
        return 0
      }
    },
    totalComp () {
      if (this.datosTabla.length) {
        let sum
        if (this.vcomboValor === 1) {
          sum = this.datosTabla.map(item => item.unicomp).reduce((prev, next) => prev + next)
        } else {
          sum = this.datosTabla.map(item => item.valorcomp).reduce((prev, next) => prev + next)
          sum = sum.toLocaleString(undefined, { minimumFractionDigits: 0 }) + '€'
        }
        return sum
      } else {
        return 0
      }
    },
    totalPorc () {
      if (this.datosTabla.length) {
        let sum, sum2, porcentaje

        if (this.vcomboValor === 1) {
          sum = this.datosTabla.map(item => item.unidades).reduce((prev, next) => prev + next)
          sum2 = this.datosTabla.map(item => item.unicomp).reduce((prev, next) => prev + next)
        } else {
          sum = this.datosTabla.map(item => item.valor).reduce((prev, next) => prev + next)
          sum2 = this.datosTabla.map(item => item.valorcomp).reduce((prev, next) => prev + next)
        }
        porcentaje = Math.round((sum2 / sum) * 100)
        return porcentaje
      } else {
        return 0
      }
    },
    totalSem () {
      if (this.datosTabla.length) {
        let sum
        if (this.vcomboValor === 1) {
          sum = this.datosTabla.map(item => item.unicomp).reduce((prev, next) => prev + next)
          if (sum < 0) return 'rojo'
          else if (sum === 0) return 'naranja'
          else return 'verde'
        } else {
          sum = this.datosTabla.map(item => item.valorcomp).reduce((prev, next) => prev + next)
          if (sum < 0) return 'rojo'
          else if (sum === 0) return 'naranja'
          else return 'verde'
        }
      } else {
        return ''
      }
    }
  },
  watch: {
    // asyncronamente cargamos los datos de nuevo cuando ha acabado la procedure
    regenerar (value) {
      console.log(`My store value for 'regenerar' changed to ${value}`)
      this.actualizarTabla()
    }
  },
  methods: {
    // handleScroll (event) {
    //   // Any code to be executed when the window is scrolled
    //   // Get the header
    //   var header = document.getElementsByClassName('v-data-table-header')

    //   // Get the offset position of the navbar
    //   var sticky = header[0].offsetHeight + 80
    //   // Add the sticky class to the header when you reach its scroll position. Remove "sticky" when you leave the scroll position
    //   if (window.pageYOffset > sticky) {
    //     header[0].classList.add('sticky')
    //   } else {
    //     header[0].classList.remove('sticky')
    //   }
    // },
    formatNum (numero) {
      if (numero !== undefined) {
        return numero.toLocaleString(undefined, { minimumFractionDigits: 0 })
      } else {
        return null
      }
    },
    getColor (item) {
      if (this.vcomboValor === 1) {
        if (item.unicomp < 0) return 'rojo'
        else if (item.unicomp === 0) return 'naranja'
        else return 'verde'
      } else {
        if (item.valorcomp < 0) return 'rojo'
        else if (item.valorcomp === 0) return 'naranja'
        else return 'verde'
      }
    },
    actualizarTabla () {
      this.cargando = true
      psApiService
        .getVentaNeta(this.vcomboTiempo, this.vcomboTipoTienda, this.$store.state.userInfo.uid)
        .then(data => {
          if (data !== "") {
            this.datosTabla = data
          } else {
            this.datosTabla = []
          }
          this.cargando = false
        })
        .catch(error => {
          util.mostrarEnConsola(
            'getVentaNeta',
            error,
            'ERROR'
          )
          this.datosTabla = []
          this.cargando = false
        })
    },
    // getAllTipoTiendaVenta () {
    //   this.comboTipoTienda = []
    //   this.comboTipoTienda.push({ id: '--', name: 'Todas' })
    //   psApiService
    //     .getAllTipoTiendaVenta()
    //     .then(data => {
    //       for (var i = 0; i < data.length; i++) {
    //         this.comboTipoTienda.push(data[i])
    //       }
    //       util.mostrarEnConsola(
    //         'getAllTipoTiendaVenta',
    //         data.length,
    //         'OK'
    //       )
    //       this.vcomboTipoTienda = '--'
    //       this.actualizarTabla()
    //     })
    //     .catch(error => {
    //       util.mostrarEnConsola('getAllTipoTiendaVenta', error, 'ERROR')
    //       this.vcomboTipoTienda = '--'
    //       this.actualizarTabla()
    //     })
    // }
  },
  created () {
    this.actualizarTabla()
    window.addEventListener('scroll', this.handleScroll)
  }
}
</script>
